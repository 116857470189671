module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="indexleftnav">\r\n  <div class="block"></div>\r\n  <ul>\r\n    <li>\r\n      <i class="iconfont icon-weixin"></i>\r\n      <p>在线咨询</p>\r\n    </li>\r\n    <li>\r\n      <i class="iconfont icon-tel"></i>\r\n      <p>电话联系</p>\r\n      <div class="subcom">\r\n        <span>18734521148</span>\r\n      </div>\r\n    </li>\r\n    <li>\r\n      <i class="iconfont icon-sao"></i>\r\n      <p>官方微信</p>\r\n      <div class="subcom">\r\n        <img src="' +
((__t = (require('./images/code.png'))) == null ? '' : __t) +
'" alt="">\r\n      </div>\r\n    </li>\r\n  </ul>\r\n</div>';

}
return __p
}