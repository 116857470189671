import './index.scss'
$(function () {
  $(".nav .topNav .item").hover(function () {
    $(this).children(".subnav").stop().slideToggle();
  });
})

// 点击打开移动端导航栏
$('.mob-menu-icon').on('click', function () {
  $('.mob-nav').addClass('show')
  $('.mob-nav-mask').fadeIn(200)
})
$('.mob-nav .close-mob-nav').on('click', function () {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})
$('.mob-nav-mask').on('click', function () {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})
//点击移动端导航列表展开子项
$('.mob-nav ul li .menu-title').on('click', function () {
  let El = $(this).parents('li')
  $(El).hasClass('active')
  if ($(El).hasClass('active')) {
    $(El).removeClass('active').find('.subnav').slideUp(450);
  } else {
    $(El).addClass('active').siblings().removeClass('active')
    $(El).find('.subnav').slideDown(450);
    $(El).siblings().find('.subnav').slideUp(450);
  }
})


$(".mob-nav .list ul li:nth-child(3)").click(function () {
  $(".model").addClass("active")
})
$(".model .iconfont").click(function () {
  $(".model").removeClass("active")
});

$(document).scroll(function () {
  var scroH = $(document).scrollTop();  //滚动高度
  if (scroH > 200) {
    $('.header').addClass("scroolActive")
  }else{
    $('.header').removeClass("scroolActive")
  }
});