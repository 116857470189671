module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- ' +
((__t = ( require('../../../node_modules/jason-webmap/template/index.ejs')() )) == null ? '' : __t) +
' -->\r\n\r\n<aside class="jason-map">\r\n  <div class="jason-map-content">\r\n    <h3 class="jason-map-heading">网站导航</h3>\r\n    <div>\r\n      <div class="jason-map-item">\r\n        <h4 class="jason-map-title"><a href="#">公司简介</a></h4>\r\n        <ul class="jason-map-ilist">\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n        </ul>\r\n      </div>\r\n      <div class="jason-map-item">\r\n        <h4 class="jason-map-title"><a href="#">公司简介</a></h4>\r\n        <ul class="jason-map-ilist">\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n          <li><a href="#">导航</a></li>\r\n        </ul>\r\n      </div>\r\n    </div>\r\n    <div class="jason-map-footer">\r\n      <h5>版权所有</h5>\r\n      <h5>xxxxxxx 有限公司 </h5>\r\n      <p class="mt-xs-10">鲁ICP备 xxxxxxx 号</p>\r\n      <p>网站设计：jason</p>\r\n    </div>\r\n  </div>\r\n</aside>\r\n';

}
return __p
}