import './index.scss'
$(window).scroll(function () {
  if ($(window).scrollTop() >= 1000) {
    $(".indexleftnav").fadeOut();
  }else{
    $(".indexleftnav").fadeIn();
  }
});

$(".indexleftnav ul li").mouseenter(function(){
  $(this).find(".subcom").stop(true,true).fadeIn();
});
$(".indexleftnav ul li").mouseleave(function(){
  $(this).find(".subcom").fadeOut();
});